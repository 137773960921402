.sign-in {
    background: #160430;
    min-height: 100vh;
    display: flex;
    /* position: relative; */
    min-width: 100% !important;
}

.sign-content {
    background-color: #160430;
    background-image: url(../../assets/sign-bg.png),
        linear-gradient(to right,
            rgba(202, 180, 233, 0.884) 70%,
            rgb(234, 232, 236) 100%);
    width: 60%;
    min-height: 100vh;
    background-repeat: no-repeat !important;
    background-size: cover;
    background-blend-mode: normal;
}

.img-fade {
    position: absolute;
    top: 0;
    right: 37%;
    width: 150px;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), #160430);
}

.fade-in {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 134px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #160430);
}

.sign-content h1 {
    color: var(--white-color);
    font-weight: bold;
    font-size: clamp(1rem, 2.5vw, 3.25rem);
    line-height: 51.5px;
    padding-left: 50px;
    position: fixed;
    bottom: 80px;
    text-transform: uppercase;
    z-index: 1;
}

.sign-content h1 span {
    color: var(--primary-color);
}

.sign-fields {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 30%;
    padding-top: 140px;
    color: var(--white-color);
    padding-left: 70px;
    z-index: 20;
}

.sign-fields h1 {
    font-weight: bold;
    font-size: clamp(2rem, 4vw, 4rem);
}

.sign-fields p {
    font-size: 15.04px;
    font-weight: bold;
}

.sign-form {
    margin-top: 36px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-width: 100%;
}

.input-with-icon {
    position: relative;
}

.input-with-icon .icon {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    color: #A4A4A4;
}

.sign-form input {
    background: #261046;
    /* min-width: 460px; */
    height: 70px;
    padding: 25px 69px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 10px;
    color: #A4A4A4;
    width: 100%;
}

.sign-fields .card-line,
.sign-up-fields .card-line {
    margin-top: 10px;
}

.sign-fields span,
.sign-up-fields span {
    text-align: center;
    color: #B6B6B6;
}

.sign-fields span a,
.sign-up-fields span a {
    font-size: 14px;
    font-weight: 600;
    color: #9D5CE9;
}

.sign-fields span a:hover,
.sign-up-fields span a:hover {
    color: #B6B6B6;
}

.input-field {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: left !important;
    width: 100%;
}

.error-msg {
    max-width: 300px;
    text-align: left !important;
    padding: 0 5px !important;
    color: #8E0E07 !important;
}

.suggestions {
    margin-top: 10px;
}

.suggestions h3 {
    margin: 0;
    font-size: 14px;
    color: var(--white-color);
}

.suggestions {
    display: flex;
    gap: 10px;
}

.suggestions p {
    padding: 5px 10px;
    background-color: #261046;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.suggestions p:hover {
    background-color: var(--primary-color);
    color: var(--white-color);
}

.password-field {
    position: relative;
}

.password-field input {
    width: 100%;
}

.toggle-password {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    color: #A4A4A4;
}

/* for soical login google or line */
.divider2 {
    display: flex;
    align-items: center;
    text-align: center;
}

.or {
    color: #b6b6b6dc !important;
    font-weight: 300;
}

.social-line {
    flex-grow: 1;
    height: 1px;
    background-color: #4A4555;
    margin: 0 10px;
}

.social-icon-row {
    display: flex;
    justify-content: center;
    padding: 10px 1rem;
    /* background-color: #261046;
    border: 1px solid #ccc; */
    border-radius: 5px;
    gap: 100px;
    align-items: center;
}

.google-button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.google-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #261046;
    border: 1px solid #ccc;
    color: #A4A4A4;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    gap: 10px;
}

.google-btn:hover {
    background-color: #160430;
}

.google-logo {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

/* .sign-form button {
    margin-top: 10px;
    background-color: var(--primary-color);
    height: 62px;
    border-radius: 18.8px;
    color: var(--white-color);
    font-size: 22.46px;
    font-weight: 500;
} */

.forgot-password {
    display: inline;
    color: #A4A4A4;
    text-align: center;
    cursor: pointer;
    font-size: 17px;
    font-weight: 600;
    padding-top: 10px;
    float: right;
}

.forgot-password:hover {
    color: white;
}

/* for forgot screen */

.forgot-mod {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1);
}

.forgot-content {
    width: 100%;
}

.forgot-content h1 {
    color: var(--white-color);
    text-transform: uppercase;
    font-size: 36px;
    text-align: center;
}


/* Paragraph text */
.forgot-content p {
    font-size: 16px;
    color: #caced8;
}

.forgot-content form {
    background-image: url("../../assets/blur-bg.png");
    width: 90%;
    max-width: 518px;
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 25px;
    gap: 20px;
    margin-top: 30px;
    overflow: hidden;
    border-radius: 12px;
}

.forgot-content input {
    border-radius: 10.03px;
    padding: 1rem;
    font-size: 18px;
    font-weight: 500;
    color: #A4A4A4;
    z-index: 12999 !important;
}

.forgot-content .resend-link {
    text-align: center;
}

.forgot-content .resend-link span {
    color: #706BEB !important;
}

.info-text {
    font-size: 14px !important;
    text-align: center;
}

.info-text a {
    color: var(--primary-color);
    text-decoration: underline;
}

/* for profile page */
.profile-content {
    display: flex;
    flex-direction: row;
}

.current-plan {
    margin-top: -20px;
    border: 2px solid #CACED8;
    border-radius: 8px;
    min-height: 100px;
    padding: 1rem;
    max-width: fit-content;
    min-width: 250px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* background-color: #243459;
    color: white; */
    background-color: #fafafa;
    color: #243459;
}

.current-plan h3 {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.current-plan h3+p {
    font-size: 12px;
    font-weight: 600;
}

.current-plan span {
    display: flex;
    align-items: baseline;
    gap: 5px;
}

.current-plan span h4 {
    font-size: 34px;
    font-weight: 500;
    color: var(--primary-color);
}

.current-plan span p {
    font-size: 16px;
    font-weight: 600;
}

.current-plan button {
    background-color: var(--secondary-color);
    padding: .5rem 1rem;
    color: white;
    font-weight: 600;
    border-radius: 8px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
}

.current-plan button:hover {
    background-color: #243459;

}

.profile-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 60px 36px;
    gap: 40px;
}

.profile-body h2 {
    color: #CACED8;
    font-size: 22px;
    font-weight: bold;
}

.profile-body form h3 {
    font-size: 15px;
    font-weight: bold;
    color: #CACED8;
}

.profile-body form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 60px;
}


.profile-body form .col {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
}

.profile-body form label {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-weight: bold;
    gap: 5px;
}

.profile-body form input {
    width: 250px;
    height: 40px;
    border: 1px solid #CACED8;
    border-radius: 5px;
    font-size: 15px;
    padding: 11px;
    color: #243459;
}

.profile-body .profile-btn {
    width: 140px;
    height: 40px;
    background-color: var(--primary-color);
    color: var(--white-color);
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    transition: background-color 0.3s;

}

.profile-body .profile-btn:hover {
    background-color: #243459;
}

/* for password change */
.profile-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    font-weight: bold;
    color: #CACED8;
}

.profile-body form span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.profile-body form span i {
    font-size: 15px;
    color: #CACED8;
    cursor: pointer;
}

.password-container {
    display: flex;
    gap: 10px;
}

.password-container button {
    background-color: #dcdee2;
    color: var(--primary-color);
    padding: 8px;
    border-radius: 5px;
    transition: background-color 0.5s, color 0.3s;
}

.password-container button:hover {
    background-color: var(--primary-color);
    color: #dcdee2;
}

/* for google auth btn */

/* for sign up */
.line-container {
    display: flex;
    align-items: start;
    color: white;
    margin-left: 1.5rem;
}

.dot-container {
    /* position: absolute; */
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50px;
    justify-content: space-between;
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
    /* Adjust as needed */
}

.line {
    flex-grow: 1;
    height: 2px;
    background-color: white;
    margin-top: 4px;
}

.dot-text {
    position: absolute;
    bottom: 0%;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: baseline;
}


.sign-up-heading {
    position: absolute;
    top: 2%;
    left: 20%;
    width: 50%;
    display: flex;
    flex-direction: column;
    text-align: center;
    z-index: 100;
    gap: 10px;
}

.sign-up-heading h2 {
    font-size: clamp(1rem, 2.5vw, 2rem);
    font-weight: bold;
    color: white;
    margin-left: 1.5rem;
}

.sign-content .home-icon {
    margin: 40px;
    cursor: pointer;
}

.sign-msg {
    background-image: url("../../assets/sign-blur.png");
    background-size: cover;
    max-width: 550px;
    height: 500px;
    margin-left: 100px;
    margin-top: 50px;
    background-repeat: no-repeat;
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 25px;
    text-align: center;
    gap: 20px;
}

.sign-msg span {
    padding: 5px 15px;
    background-color: #DCFCE7;
    border-radius: 35px;
    color: #8343CD;
    font-size: 12px;
    font-weight: 500;
}

.sign-msg h3 {
    font-size: 30px;
    font-weight: 500;
    color: white;
}

.sign-msg p {
    font-size: 18px;
    font-weight: 600;
    color: white;
}

.sign-msg h4 {
    font-size: 19px;
    font-weight: normal;
    color: #8896AB;
}

.sign-up-fields {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 85px;
    padding-top: 8%;
    z-index: 20;
    padding-bottom: 50px;
}

.sign-up-fields form {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.sign-up-fields form input,
.sign-up-fields form textarea {
    padding: 25px;
    border-radius: 10px;
    background-color: #261046;
    font-size: 16px;
    font-weight: 500;
    color: #A4A4A4;
}

.sign-up-fields form label {
    color: #B6B6B6;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: .5rem;

}

.sign-up-fields form label span {
    color: #9D5CE9;
}

.sign-up-fields form button {
    background-color: var(--primary-color);
    border-radius: 18.8px;
    color: var(--white-color);
    font-size: 22.46px;
    font-weight: 500;
    cursor: pointer;
    min-width: fit-content;
    padding: 1rem;
}

/* for choose plans */
.choose-plans {
    background-image: url("../../assets/hero-bg.png");
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
}



.choose-plans .sign-up-heading {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    margin: 20px auto;
    padding: 10px;
    padding-bottom: 0px;
}


.choose-plans .sign-up-heading h2 {
    color: #1B2534;
}

.choose-plans .dot {
    background-color: #706BEB;
}

.choose-plans .line {
    background-color: #213157;
}

.choose-plans .dot-text {
    color: #213157;
}

/* for new plan page */
.new-plan-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5rem;
}

.new-plan-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 5rem;
    background: var(--blue-grad);
    min-width: 50%;
    max-width: 90%;
    box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.1);
    border: 1px solid var(--primary-color);
    border-radius: 20px;
    padding: 1.5rem 2.5rem;
    color: white;
    min-height: 500px;
}

.new-plan-feature {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.new-plan-feature ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    gap: 1rem;
}

.new-plan-feature ul li {
    min-width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    font-size: 24px;
    font-weight: 500;
}

.new-plan-feature ul li span {
    display: flex;
    align-self: flex-end;
    width: 30px;
    height: 30px;
    background-color: #FAE0E1;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #39C516;
}

.new-plan {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    /* justify-content: center; */
    align-items: center;
    background-color: white;
    color: var(--primary-color);
    border: 5px solid rgb(250, 224, 225);
    box-shadow: 0 0 20px 20px rgba(250, 224, 225, 0.3);
    padding: 2.5rem;
    border-radius: 10px;
    min-width: 40%;
}

.new-plan h1 {
    background-image: var(--blue-grad);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: clamp(2.5rem, 3vw, 4rem);
    font-weight: bold;
}

.new-plan-info {
    background: var(--blue-grad);
    color: white;
    font-size: 20px;
    font-weight: 500;
    padding: 0.3rem 1.9rem;
    border-radius: 3px;
    border: 1px solid var(--primary-color);
}

.new-plan-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.new-plan-line {
    width: 90%;
    height: 1px;
    background: var(--blue-grad);
    border-radius: 2px;
}

.new-plan h3 {
    color: black;
    font-size: 30px;
    font-weight: 700;
}

.new-plan p {
    text-align: center;
    color: black;
}

.new-plan-btn {
    background-color: var(--primary-color);
    color: white;
    padding: 15px 40px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 13px;
    font-weight: 600;
    transition: background-color 0.5s;

    &:hover {
        background-color: #403E88;
    }
}

/* for plans page */
.plans-page {
    min-width: 100%;
    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;

}

.plans-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 30px;
    min-height: 100%;
    margin-top: 20px;
}

.plan.selected {
    box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.1);
}

.plan {
    position: relative;
    width: 360px;
    height: 600px;
    background-color: #F9F9F9;
    padding: 45px 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: background-color 0.5s, color 0.5s;
}

.plan-header {
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.plan-header h2 {
    font-size: 40px;
    font-weight: bold;
}

.offer {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: white;
    padding: 11px 15px;
    border-radius: 27px;
    color: #797979;
    font-size: 13px;
    font-weight: bold;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.plan-line {
    width: 100%;
    height: 1px;
    background-color: #5236FF;
    margin: 20px 0px;
}

.plan-price {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    gap: 20px;
}

.plan-price p span {
    font-size: 30px;
}

.plan-price p {
    font-size: 15px;
    font-weight: bolder;
    color: #242331;
}

.plan-price button {
    background-color: #706BEB;
    width: 188px;
    height: 41px;
    border-radius: 30px;
    color: white;
    font-size: 12px;
    font-weight: 500;
}

.plan-detail {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 20px;
    font-size: 15px;
    color: #242331;
}

.plan-detail span {
    color: #797979;
}

.plan .plan-btn {
    margin-top: 30px;
    background: transparent;
    border: 1px solid var(--primary-color);
    padding: 17px 30px;
    border-radius: 25px;
    color: #242331;
    font-size: 12px;
    font-weight: bold;
    transition: background-color 0.5s, color 0.3s;
}

/* 
.plan .plan-btn:hover {
    background: var(--primary-color);
    color: var(--white-color);
} */

.plans-row .plan:hover {
    background-color: var(--primary-color);
    transition: background-color 0.5s, color 0.3s;
    cursor: pointer;
}

.plan:hover .plan-header {
    color: white;
}

.plan:hover .plan-line {
    background-color: white;
}

.plan:hover .plan-price p {
    color: white;
}

.plan:hover .plan-detail {
    padding: 0;
}

.plan:hover .plan-price button {
    background-color: #FAE0E1;
    color: var(--primary-color);
}

.plan:hover .plan-btn {
    background: white;
    border: 1px solid var(--white-color);
}

.plan:hover .plan-btn:hover {
    background: var(--accent-color);
    border: 1px solid var(--white-color);
    color: var(--white-color);
}

.proceed-btn {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;

}

.proceed-btn button {
    background-color: var(--primary-color);
    margin-top: 30px;
    max-width: 350px;
    height: 60px;
    padding: 18px 43px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: 500;
    color: white;
    cursor: pointer;
}


/* for checkout detail */
.checkout-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 100px;
    flex-wrap: wrap;
    padding-bottom: 50px;
}

.checkout-detail {
    min-height: 100vh;
    width: 40%;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-image: url("../../assets/checkout-shape.png"); */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.checkout-detail-card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 400px;
    max-height: 500px;
    backdrop-filter: blur(10px);
    background-image: url("../../assets/checkout-bg.png");
    background-size: cover;
    background-position: top;
    padding: 2rem;
    border-radius: 10px;
}

.checkout-detail-card p {
    font-size: 24px;
    font-weight: 500;
}

.checkout-detail-card h3 {
    font-size: clamp(2.5rem, 3vw, 4rem);
    font-weight: bold;
    margin-bottom: 30px;
}

.checkout-detail-card .row {
    justify-content: space-between;
}

.checkout-detail-card .row b {
    font-size: 26px;
    font-weight: bold;
    text-transform: capitalize;
}

.checkout-detail-card .row p {
    font-size: 24px;
    font-weight: 500;
}

.checkout-detail-card span {
    font-size: 20px;
    color: #575757;
    margin-bottom: 30px;
    display: block;
}

.card-line {
    width: 100%;
    height: 1px;
    background-color: #747474;
    border-radius: 1px;
    margin-top: 30px;
}

/* for payment detail */
.payment-detail {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.payment-detail h2 {
    color: #4F0669;
    font-size: 24px;
    font-weight: normal;

}

.credit-card {
    width: 550px;
    height: 300px;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.51) 0%, rgba(255, 255, 255, 0) 100%);
    border-radius: 15px;
    padding: 40px;
    display: inline-block;
    margin-bottom: 20px;
    border: 1px solid white;
}

.card-number {
    font-size: 35px;
    margin: 15px 0px;
}

.card-name {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    margin-bottom: 10px;
    font-size: 24px;
}

.credit-card .row {
    gap: 50px;
    margin-top: 20px;
}

.credit-card .row span {
    display: block;
    font-size: 12px;
    font-weight: normal;
}

.expiry-date,
.cvv {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin-right: 10px;
    font-size: 24px;
}

.credit-card-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 500px;
}

.credit-card-form label {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: #213157;
    font-weight: normal;
    text-transform: uppercase;
}


.card-element-wrapper {
    width: 100%;
    max-width: 400px;
    padding: 10px;
}

.payment-text {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.payment-text h3 {
    color: #929FB1;
    font-size: 24px;
    font-weight: bold;
}

.payment-text span {
    background-color: #F6F7F9;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.payment-text p {
    margin-top: 1rem;
    width: 80%;
    font-size: 14px;
    font-weight: 300;
}

.checkout-btn {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    max-width: 500px;
    margin-top: 20px;
}

.checkout-btn p {
    width: 50%;
    display: flex;
    height: 50px;
    justify-content: center;
    align-items: center;
    background-color: #FAE0E1;
    color: #494949;
    font-size: clamp(1rem, 2vw, 1.5rem);
    text-align: center;
}

.checkout-btn button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50%;
    font-size: clamp(1rem, 2vw, 1.5rem);
    /* font-size: 22px; */
    font-weight: bold;
    color: white;
    background-color: var(--primary-color);
}

.deactivate-body {
    max-width: 650px;
    min-height: 450px;
    border-radius: 30px;
    background-color: white;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 50px;
    text-align: center;
    gap: 20px;
}

.deactivate-body .icon {
    background-color: #E74236;
    width: 110px;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

}

.d-acount-btn {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.d-acount-btn button {
    width: 150px;
    height: 50px;
    border-radius: 25px;
    font-size: 13px;
    font-weight: bold;
}

.d-acount-btn button:first-child {
    background-color: transparent;
    border: 1px solid black;
}

.d-acount-btn button:last-child {
    background-color: #706BEB;
    border: 1px solid #706BEB;
    color: white;
}

.checkmark {
    color: #9ABC66;
    font-size: 100px;
    line-height: 200px;
    margin-left: -15px;
}

.error-icon {
    color: rgb(250, 68, 68);
    font-size: 100px;
    line-height: 230px;
}

/* for resposive */
@media screen and (max-width: 1030px) {

    .new-plan-feature,
    .new-plan,
    .sign-up-heading {
        width: 90%;
    }

    .sign-fields {
        padding: 30px;
    }
}

/* for 768 */
@media screen and (max-width: 768px) {
    .sign-content {
        display: none;
    }

    .sign-in {
        display: flex;
    }


    .sign-fields {
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0px;
        margin: 0 20px;
        overflow-x: hidden;
    }

    .sign-fields input {
        padding-right: 5px;
    }

    .sign-up-fields form button {
        font-size: 16px;
    }

    .sign-up-heading {
        width: 90%;
        left: 20px;
    }



    .sign-up-fields {
        padding: 100px 20px;
        justify-content: center;
        align-items: center;
        width: 100%;
        /* background-image: url("../../assets/sign-bg.png");
        background-repeat: no-repeat;
        min-height: 100vh; */
    }

    .sign-up-fields form {
        margin-top: 20px;
    }

    .sign-up-fields form label {
        font-size: 10px;
    }

    .sign-up-fields .row {
        flex-wrap: wrap;
    }

    .sign-up-fields .row input {
        width: 100%;
    }

    .profile-body form .row {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }



    .profile-body form label,
    .profile-body form input {
        width: 100%;
    }

    .profile-body {
        padding: 20px;
    }

    .profile-header p {
        font-size: 10px;
    }

    /*  */
    .forgot-content h1 {
        font-size: 22px;
    }

    .forgot-content form {
        padding: 15px;
        margin-top: 40px;
    }
}

.profile-label {
    display: flex !important;
    gap: 5px;
}

.profile-label span {
    font-size: 10px;
    color: #CACED8;
}

@media screen and (max-width: 480px) {

    .new-plan-section,
    .new-plan {
        padding: 20px;
    }

    .new-plan-info {
        padding: 10px;
        font-size: 14px;
    }

    .new-plan-feature ul li {
        font-size: 16px;
    }

    .checkout-btn,
    .prev-card {
        max-width: 85vw;
    }

    .sign-form input {
        padding: 20px 35px;
        padding-right: 5px;
    }


    .input-with-icon .icon {
        left: 10px;
        width: 16px;
        height: 16px;
    }

    .toggle-password {
        width: 16px;
        height: 16px;
    }

    .plan {
        width: 90%;
    }

    .proceed-btn button {
        font-size: 16px;
    }

    .forgot-content h1 {
        font-size: 16px;
    }

    .forgot-content p {
        font-size: 12px;
    }
}