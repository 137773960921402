.login-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
}

.login-page img {
    width: 500px;
}

.login-page form {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.login-page form label {
    display: flex;
    flex-direction: column;
    gap: 15px;
}


.login-page input {
    width: 300px;
    height: 50px;
    border-radius: 20px;
    border: 1px solid #EBEBEB;
    padding: 15px 20px;
    color: #7A86A1;
    font-size: 14px;
}

.login-page form .checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #7A86A1;
}

.checkbox input {
    width: 20px;
}

.login-page form button {
    background: linear-gradient(135deg, var(--secondary-color) 0%, var(--primary-color) 96.04%);
    width: 300px;
    height: 55px;
    margin: 0 auto;
    color: white;
    font-size: 16px;
    border-radius: 22px;
    margin-top: 30px;
}
