@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

:root {

  /* font fmaily */
  --font-family-reg: 'Inter';

  --blue-grad: linear-gradient(90deg, hsla(242, 72%, 66%, 1) 0%, hsla(242, 38%, 38%, 1) 100%);

  /* background color */
  --bg-color: #ffffff;
  --shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  /* colors */
  --primary-color: #706BEB;
  --secondary-color: #EBB1FF;
  --accent-color: #243459;
  --text-color: #141414;
  --neutral-color: #212121;
  --white-color: #ffffff;

}