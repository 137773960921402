/* NotFound.css */
.not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    gap: 20px;
    padding: 20px;
    background-color: #f8f9fa;
}

.not-found-image {
    width: 100%;
    max-width: 800px;
    height: auto;
}

.not-found-title {
    font-size: 2rem;
    font-weight: bold;
    color: #343a40;
    margin: 0;
}

.not-found-text {
    font-size: 1rem;
    color: #6c757d;
    margin: 0;
}

.not-found-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1rem;
    transition: background-color 0.3s;
}

.not-found-button:hover {
    background-color: #0056b3;
}